import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import NavBar from "./component/navbar/index";

import Footer from "./component/footer/index";
import Home from "./pages/home";
import About from "./pages/about";
import BookingConsult from "./pages/booking/index";
import UserRegister from "./pages/register/index";
import ConsultDoctor from "./pages/consult-doctor/consultDoctor2";
import DectorProfile from "./pages/dector-profile/dectorProfile";
import HopistalDectorProfile from "./pages/dector-profile/hospitalDoctorprofile";
import ClinicsDectorProfile from "./pages/dector-profile/clinicsDectorProfile";
import HomecareService from "./pages/homecareService/homecareService";
import HomecareCategory from "./pages/homecareService/homecareCategory";
import HomecareCategoryPackages from "./pages/homecareService/homecareCategoryCompare";
import HomecarePackageCompare from "./pages/homecareService/homecarePackageCompare";
import HomecareServiceBooking from "./pages/package-login/HomecareServiceBooking";
import ConsultDectorEarlistSlot from "./pages/consult-dector-earlist-slot/consultDectorEarlistSlot";
import BookingConfirmed from "./pages/Booking-Confirmed/BookingConfirmed";
import ConsultHospital from "./pages/consult-hopsital/consultHospital";
import ConsultHopsitalDector from "./pages/consult-hopsital/consultHopsitalDector";
import ConsultClinicsDector from "./pages/consult-clinics/consultClinicsDector";
import BookPackageOrTest from "./pages/book-package-or-test";
import PackageComparison from "./pages/package-comparison/packageComparison";
import PackageBooking from "./pages/book-package-or-test/package/packageBooking";
import PackageBookingConfirmed from "./pages/Booking-Confirmed/packageBookingConfirmed";
import OrderMedicine from "./pages/order-medicine/OrderMedicine";
import CarePackageCategories from "./pages/care-package/carePackageCategories";
import CarePackages from "./pages/care-package/carePackages";
import CareBookingConfirmed from "./pages/care-package/careBookingConfirmed";
import Blog from "./pages/blog/blog";
import BlogDetails from "./pages/blog/blog-details";

import Partners from "./pages/partners/partners";
import TermsAndConditions from "./pages/terms-and-conditions/termsAndConditions";

import PatientDashBoard from "./pages/patient-portal/patient-dashboard";
import PatientMyBooking from "./pages/patient-portal/patientMyBooking";
import PatientMyprescriptions from "./pages/patient-portal/patientMyprescriptions";
import PatientMyDocument from "./pages/patient-portal/patientMyDocument";
import PatientMyReport from "./pages/patient-portal/patientMyReport";
import { useDispatch, useSelector } from "react-redux";
import { locationReducer } from "./redux/slice/location";
import { getLatLongDetail } from "./actions/get-calls";

import CarePackageBooking from "./pages/care-package/carePackageBooking";
import TestBooking from "./pages/book-package-or-test/test/testBooking";
import PrivacyPolicy from "./pages/privacy-policy/privacyPolicy";
import TermsOfUse from "./pages/terms-of-use/termsOfUse";
import ContactUs from "./pages/contact-us/contactUs";

import CareerMain from "./pages/careers/careermain";
import CareerSub from "./pages/careers/careersub";
import FAQ from "./pages/faq/faq";
import PurchaseDevice from './pages/purchaseDevice/purchaseDevice'
import ProfileEdit from './pages/my-profile/profile-edit';
import ConsultSpecialist from "./pages/consult-specialities/consultSpecialist";
import LandingPage from "./pages/landing-page";
import Insights from "./pages/insights/Insight";
import CarePackageEyeButton from "./pages/care-package/CarePackageEyeButton";
import PackageTestDetail from "./pages/book-package-or-test/packageTestEyeButton";
function App() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  // const location = useLocation()
  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          if (position && position.coords) {
            setStatus(null);
            const _locationDetail = await getLatLongDetail(
              position.coords.latitude,
              position.coords.longitude
            );

            if (
              _locationDetail &&
              _locationDetail.data &&
              _locationDetail.data.results
            ) {
              dispatch(locationReducer(_locationDetail.data.results[0]));
            }
          }
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <React.Fragment>
      <Router>
        {!window.location.pathname.includes('show_landingpage') && <NavBar />}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/insights" element={<Insights />} />

          <Route path="/user-register" element={<UserRegister />} />

          <Route
            path="/book-consult"
            element={<Navigate to="/book-consult/hospitals" />}
          />
          <Route
            path="/book-consult/hospitals"
            element={<BookingConsult />}
          />
                    <Route
            path="/book-consult/hospitals/:slug/:hId"
            element={<ConsultHospital />}
          />
          ConsultHospital
          <Route
            path="/book-consult/specialities"
            element={<BookingConsult />}
          />
          <Route
            path="/book-consult/specialities/:specialistName/:sId"
            element={<ConsultSpecialist />}
          />
          <Route path="/book-consult/clinics" element={<BookingConsult />} />
          <Route path="/book-consult/doctors" element={<BookingConsult />} />
          <Route path="/hospital-consult/:hId" element={<ConsultHospital />} />
          <Route
            path="/book-consult/consult-doctor/:hospitalName/:Id/:sId"
            element={<ConsultDoctor />}
          />
          <Route
            path="/book-consult/clinics/:specilityName/:Id/:sId"
            element={<ConsultDoctor />}
          />
          
          <Route
            path="/book-consult/hospitals/:hospitalSlug/:specialitySlug/:Id/:sId"
            element={<ConsultDoctor />}
          />
                    <Route
            path="/consult-doctor/:hospitalSlug/:specialitySlug/:doctorSlug/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          />
          <Route
            path="/book-consult/consult-doctor-earlist-slot/:Id/:sId"
            element={<ConsultDectorEarlistSlot />}
          />
                    <Route
            path="/book-consult/consult-doctor-earlist-slot/:sId"
            element={<ConsultDectorEarlistSlot />}
          />
          {/* <Route
            path="/doctor-profile/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          /> */}
          <Route
      path="/book-consult/hospitals/:hospitalSlug/:specialitySlug/:doctorNameSlug/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          />
          <Route
      path="/book-consult/specialities/:specialitySlug/:doctorNameSlug/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          />
          <Route
      path="/book-consult/clinics/:specialitySlug/:doctorNameSlug/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          />
          
          <Route
      path="/book-consult/doctors/:doctorNameSlug/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          />
          <Route
      path="/book-consult/:hospitalSlug/:doctorNameSlug/:dId/:hId/:sId/:cId"
            element={<DectorProfile />}
          />

          <Route path="/homecare-services" element={<HomecareService />} />
          <Route
            path="/homecare-service-category/:categoryName/:categoryId"
            element={<HomecareCategory />}
          />
          <Route
            path="/homecare-services/:slug/:categoryName/:categoryId"
            element={<HomecareCategory />}
          />
          
          <Route
            path="/homecare-service-packages/:categoryId/:hospitalId/:categoryName/:subCatName"
            element={<HomecareCategoryPackages />}
          />
          <Route
            path="/homecare-service-package-compare/:hospitalId/:compare1/:compare2/:categoryName/:subCatName"
            element={<HomecarePackageCompare />}
          />
          <Route
            path="/homecare-service-booking/:categoryName/:subCatName"
            element={<HomecareServiceBooking />}
          />

          <Route path="/Booking-confirmed/:Id" element={<BookingConfirmed />} />

          <Route
            path="/hospital-dector-profile"
            element={<HopistalDectorProfile />}
          />
          <Route
            path="/consult-hopsital-dector"
            element={<ConsultHopsitalDector />}
          />

          <Route
            path="/clinics-dector-profile"
            element={<ClinicsDectorProfile />}
          />
          <Route
            path="/consult-clinics-dector"
            element={<ConsultClinicsDector />}
          />

          <Route
            path="/book-package-or-test"
            element={<Navigate to="/book-package-or-test/packages" />}
          />
          <Route
            path="/book-package-or-test/packages"
            element={<BookPackageOrTest />}
          />
          <Route
            path="/book-package-or-test/test"
            element={<BookPackageOrTest />}
          />
          <Route
            path="/package-comparison/:categoryName/:compare1/:compare2"
            element={<PackageComparison />}
          />
          <Route path="/book-package-or-test/packages/:slug/package-booking" element={<PackageBooking />} />
          <Route path="/package-booking" element={<PackageBooking />} />
          <Route path="/package-test-booking/:dId" element={<PackageTestDetail />} />
          <Route path="/test-booking" element={<TestBooking />} />
          <Route
            path="/package-booking-confirm"
            element={<PackageBookingConfirmed />}
          />

          <Route path="/order-medicine" element={<OrderMedicine />} />

          <Route
            path="/care-package-categories"
            element={<CarePackageCategories />}
          />
          <Route
            path="/care-packages/:categoryName/:categoryId"
            element={<CarePackages />}
          />
          <Route
            path="/care-package-comparison/:categoryName/:compare1/:compare2"
            element={<PackageComparison />}
          />
          <Route
            path="/care-package-booking/:categoryName/:subCategoryName"
            element={<CarePackageBooking />}
          />
          <Route
            path="/care-booking-confirmed"
            element={<CareBookingConfirmed />}
          />
          <Route
            path="/care-package-info"
            element={<CarePackageEyeButton />}
          />

          <Route path="/health-blogs" element={<Blog />} />
          {/* <Route path="/blog-details/:title" element={<BlogDetails />} /> */}
          <Route path="/health-blogs/:slug" element={<BlogDetails />} />

          <Route path="/partners" element={<Partners />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/purchase-device" element={<PurchaseDevice />} />

       
          {/* show_landingpage/full-body-check-ups-redcliffe */}
          <Route path="/show_landingpage/:slug" element={<LandingPage />} />

          <Route path="/career" element={<CareerMain />} />
          
          <Route path="/career-sub/:slug" element={<CareerSub />} />

          <Route path="/faq" element={<FAQ />} />

          {/* dashboard Link */}
          <Route path="/patient-dashboard" element={<PatientDashBoard />} />
          <Route path="/patient-my-booking" element={<PatientMyBooking />} />
          <Route path="/my-profile" element={<ProfileEdit />} />   
          <Route
            path="/patient-my-prescription"
            element={<PatientMyprescriptions />}
          />
          <Route path="/patient-my-document" element={<PatientMyDocument />} />
          <Route path="/patient-my-report" element={<PatientMyReport />} />
        </Routes>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
