import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import phoneCall from "../../assets/images/phone-call.svg";
import logo from "../../assets/images/logo-myhealthcare.svg";
import loginUser from "../../assets/images/login-User.png";
import MHCLocation from "../mhc-location";

import { getOTP, switchPatient, verifyMobileOTP } from "../../actions/post-call";
import { getProfile } from "../../actions/get-calls";

import { setCallTokenReducer, clearCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer, clearSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer, clearAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer, clearSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer, clearUserProfileReducer } from "../../redux/slice/user-profile";
import { clearSelectedMembersReducer } from "../../redux/slice/selected-members";
import { clearSelectedMemberIdReducer } from "../../redux/slice/selected-member-id";
import { calculateAge } from "../../constants/app-constants";
import { clearDeliveryReducer } from "../../redux/slice/delivery-address";

const NavBar = () => {
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [callKey, setCallKey] = useState(null);
  const [switchKey, setSwitchKey] = useState(null);
  const [userList, setUserList] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isSubMunu, setIsSubMunu] = useState(true);
  const [isLoginSubMenu, setIsLoginSubMenu] = useState(true);
  const [isAfterLoginSubmenu, setIsAfterLoginSubMenu] = useState(true);
  const [open, setOpen] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState('');
  const [otpM, setOtpM] = useState();
  const [otpRe, setOtpRe] = useState(false);
  const [otpNum, setOtpNum] = useState();

  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  let wrapperRef = React.createRef();

  useEffect(() => {
    const _handleClickOutside = handleClickOutside(this);
    document.addEventListener("mousedown", _handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    }
  }, [])

  function handleClickOutside(event = null) {
    if (wrapperRef && event && !wrapperRef.current.contains(event.target)) {
      setIsLoginSubMenu(true);
    }
  }

  useEffect(() => {
    if (callToken) setCallKey(callToken);
  }, [callToken]);

  useEffect(() => {
    if (switchToken) setSwitchKey(switchToken);
  }, [switchToken]);

  useEffect(() => {
    if (allUsers) setUserList(allUsers);
  }, [allUsers]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserProfile(selectedUser);
      setCurrentUserId(selectedUser.id);
    }
  }, [selectedUser]);

  function handlenavbar() {
    setIsNavCollapsed(!isNavCollapsed);
  }

  function handleLogout() {
    if (selectedUser) {
      dispatch(clearCallTokenReducer());
      dispatch(clearSwitchTokenReducer());
      dispatch(clearAllUsersReducer());
      dispatch(clearSelectedUserReducer());
      dispatch(clearUserProfileReducer());
      dispatch(clearSelectedMembersReducer());
      dispatch(clearSelectedMemberIdReducer());
      dispatch(clearDeliveryReducer());
    }

    localStorage.clear();
    window.location.reload();
  }

  function closeNaveBar() {
    setIsNavCollapsed(true);
    setIsLoginSubMenu(true);
    setIsAfterLoginSubMenu(true);
    setIsSubMunu(true);
  }

  function handlesubmune() {
    setIsSubMunu(!isSubMunu);
    setIsLoginSubMenu(true);
    setIsAfterLoginSubMenu(true);
  }

  function handleLoginSubMenu() {
    setIsLoginSubMenu(!isLoginSubMenu);
    setIsSubMunu(true);
    setIsAfterLoginSubMenu(true);
  }

  function handleAfterLoginSubmenu() {
    setIsAfterLoginSubMenu(!isAfterLoginSubmenu);
    setIsSubMunu(true);
    setIsLoginSubMenu(true);
  }

  function handlecollspe() {
    setOpen(!open);
  }

  function getOTPMobile() {
    getOTP(phone)
      .then(function (login_response) {
        let login_res = login_response.data;

        if (login_res.errors && login_res.errors.length > 0) {
          setOtpM(login_res.errors[0].message);
          setOtpRe(true);
          return false;
        }

        setIsResend(true);
        setTimeLeft(30);
        setShowOTPForm(true);
      })
      .catch(function () { });
  }

  function verifyOTPMobile() {
    verifyMobileOTP({
      isd_code: "91",
      otp: otpNum,
      uid: phone,
    })
      .then(function (response) {
        let res = response.data;
        if (res.errors && res.errors.length > 0) {
          return toast.error(res.errors[0].message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false
          });
        }

        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));

        setOtpRe(false);
        setCurrentUserId(res.users[0].id);

        if (res && res.users && res.users.length > 1) {
          switchUser(res.users[0].id, response.headers.authorization);
        }
      })
      .catch(function (err) { });
  }

  function switchUser(id, token) {
    switchPatient({ patient_id: id }, token ? token : callKey)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;

        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();
            }
          }).catch(function (err) { });

        setShowConfirmation(false);
      }).catch(function (err) { });
  }

  // function switchProfile(e) {
  //   setShowConfirmation(true);
  //   setCurrentUserId(e);
  // }

  function confirmSwitch(e) {
    e.preventDefault();
    switchUser(currentUserId, null);
    setOpen(false);
  }

  function cancelSwitch() {
    setShowConfirmation(false);
    setOpen(false);
  }

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white ">
      <div className="container position-relative">
        <Link className="navbar-brand" to="/">
          <img src={logo} />
        </Link>

        <img src={phoneCall} className="mob-phone-call d-block d-lg-none" />
        <div
          className={`menu-toggle d-lg-none ${isNavCollapsed ? "" : "on"}`}
          onClick={handlenavbar}>
          <div className="line-one"></div>
          <div className="line-two"></div>
          <div className="line-three"></div>
        </div>

        <div className={`${isNavCollapsed ? "" : "mob-submenu"} navbar-collapse`} id="navbarSupportedContent">
          <ul className="navbar-nav  ms-auto  mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link " onClick={closeNaveBar} aria-current="page" href="/book-consult/hospitals">
                Hospitals
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" onClick={closeNaveBar} aria-current="page" href="/book-consult/specialities">
                Specialities
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link " onClick={closeNaveBar} aria-current="page" href="/book-consult/clinics">
                Clinics
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link " onClick={closeNaveBar} aria-current="page" href="/book-consult/doctors">
                Doctors
              </a>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle d-none d-lg-block" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </a>
              <span
                className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service"
                onClick={handlesubmune}>
                Services
                {isSubMunu === true ? (
                  <i className="mhc-icon icon-plus"></i>
                ) : (
                  <i className="mhc-icon  icon-minus"></i>
                )}
              </span>
              <ul className={`${isSubMunu ? "mobsubnone" : "mobsublock"} dropdown-menu`} aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" onClick={closeNaveBar} aria-current="page" to="/book-consult">
                  Book Consult
                </Link>
                <Link className="dropdown-item" onClick={closeNaveBar} aria-current="page" to="/book-package-or-test">
                  Book Tests
                </Link>
                <Link className="dropdown-item" onClick={closeNaveBar} aria-current="page" to="/order-medicine">
                  Order Medicines
                </Link>
                <Link className="dropdown-item" onClick={closeNaveBar} aria-current="page" to="/homecare-services">
                  Book Homecare
                </Link>
                <Link className="dropdown-item" onClick={closeNaveBar} aria-current="page" to="/care-package-categories">
                  Care Programs
                </Link>
                <Link className="dropdown-item" onClick={closeNaveBar} aria-current="page" to="/purchase-device">
                  Purchase device
                </Link>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link " onClick={closeNaveBar} aria-current="page" to="/health-blogs">
                Blogs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " onClick={closeNaveBar} aria-current="page" to="/partners">
                For Partners
              </Link>
            </li>
            {(selectedUserProfile == null) ? (
              <li className="nav-item dropdown order-first order-lg-last">
                <a className="nav-link dropdown-toggle d-none d-lg-block" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => handleLoginSubMenu(e)}>
                  <span className="icon-user-_1_ "></span>
                  <span className="ms-1">Login | Register</span>
                </a>
                <span
                  className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service"
                  onClick={(e) => handleLoginSubMenu(e)}>
                  Login | Register {isLoginSubMenu === true ? (<i className="mhc-icon  icon-plus"></i>) : (<i className="mhc-icon  icon-minus"></i>)}
                </span>
                <ul className={`${isLoginSubMenu ? "mobsubnone1" : "mobsublock1"} dropdown-menu dropdown-right`} aria-labelledby="navbarDropdown" ref={wrapperRef}>
                  <li>
                    <div className="nav-reigster-form">
                      <div className="d-flex">
                        <div className="w-25">
                          <select
                            className="form-select"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                          >
                            <option value="91">+91</option>
                          </select>
                        </div>
                        <div className="w-75">
                          <div className="input-group enter-otp">
                            <input
                              type="text"
                              maxLength="10"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Mobile Number"
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                            />
                            <button
                              type="submit"
                              className=" rounded-end text-white bg-primary px-3"
                              id="basic-addon1"
                              onClick={() => getOTPMobile()}>
                              Send OTP
                            </button>
                          </div>
                        </div>
                      </div>
                      {otpRe === true && (<><p className="m-0 fs-7 text-primary mt-2">{otpM}</p></>)}

                      {
                        showOTPForm && (
                          <>
                            <div className="input-group enter-otp mt-3">
                              <input
                                type="text"
                                className="form-control rounded-start"
                                placeholder="Enter OTP"
                                maxLength="4"
                                aria-label="Enter OTP"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                  setOtpNum(e.target.value);
                                }}
                              />
                              <button
                                onClick={() => getOTPMobile()}
                                type="submit"
                                className="input-group-text rounded-end text-primary"
                                id="basic-addon1" disabled={isResend}>Resend OTP{isResend && (' in ' + timeLeft + 's')}</button>
                            </div>

                            <p className="mb-2 mt-2">
                              By logging in, you agree to our <a href="/p" className="text-primary">Privacy Policy</a>
                            </p>

                            <button
                              className="btn btn-primary rounded-1"
                              onClick={(e) => { verifyOTPMobile() }}>
                              Submit
                            </button>
                          </>
                        )
                      }

                      <p className="mt-2 mb-0">
                        Don't have an account yet? <Link to="/user-register" onClick={closeNaveBar} className="text-primary">Register</Link>
                      </p>
                    </div>
                  </li>
                </ul>
              </li>
            ) : (
              <li className="nav-item dropdown order-first order-lg-last">
                <a
                  className="nav-link dropdown-toggle d-none d-lg-block"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src={loginUser} className="loginUser" />
                  <span className="ms-1">{selectedUserProfile && selectedUserProfile.full_name}</span>
                </a>
                <span className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service" onClick={handleAfterLoginSubmenu}>
                  {selectedUserProfile && selectedUserProfile.full_name} {isAfterLoginSubmenu === true ? (<i className="mhc-icon icon-plus"></i>) : (<i className="mhc-icon icon-minus"></i>)}
                </span>
                <ul className={`${isAfterLoginSubmenu ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right`} aria-labelledby="navbarDropdown">
                  <li className="dropdown-item pb-2  cursor-pointer border-bottom" onClick={handlecollspe} aria-expanded={open} >
                    <span className="mhc-icon icon-exchange me-2"></span> Switch
                    Profile
                  </li>
                  <li className="p-0">
                    <div className={`collapse collapse-switch ${open === true && "show"}`} >
                      <ul className="list-unstyled">
                        {
                          userList && userList.map((user_item) => {
                            return (
                              <li className="dropdown-item py-2 border-bottom" key={user_item.id}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="switchUser"
                                    id={'navUserId_'+user_item.id}
                                    value={user_item.id}
                                    checked={'navUserId_'+currentUserId === 'navUserId_'+user_item.id}
                                    onChange={(e) => { setCurrentUserId(e.target.value); setShowConfirmation(true); }}
                                  />
                                  <label className="form-check-label ms-2" htmlFor={'navUserId_'+user_item.id}>
                                    {user_item.full_name}
                                    <br />
                                    <small>{user_item.gender}, {calculateAge(user_item.dob)}</small>
                                  </label>
                                </div>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </li>

                  {
                    showConfirmation && (
                      <li className="dropdown-item py-2 border-bottom">
                        <div className="profilebtn">
                          <span type="button" onClick={cancelSwitch}
                            className="btn btn-outline btn-outline-secondary rounded-1 px-2 py-0">
                            Cancel
                          </span>
                          <button type="button" onClick={(e) => confirmSwitch(e)} className="btn btn-outline btn-primary py-0 px-2 rounded-1 ms-3">Continue</button>
                        </div>
                      </li>
                    )
                  }

                  <li className="dropdown-item py-2 border-bottom">
                    <Link onClick={closeNaveBar} to="/patient-dashboard"><span className="mhc-icon icon-dashboard me-2"></span>Dashboard</Link>
                  </li>
                  <li className="dropdown-item pt-2">
                    <a className="cursor-pointer"
                      onClick={(closeNaveBar, handleLogout)}>
                      <span className="mhc-icon icon-log-out me-2"></span>
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
          <form className="d-none d-lg-flex w-175">
            {/* {
                   pathname==="/" ? '':  <MHCLocation isNavbar={true} />
                 } */}

            <MHCLocation isNavbar={true} />
          </form>
        </div>
      </div>
      <ToastContainer />
    </nav>
  );
};

export default NavBar;
