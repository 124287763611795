import React, { useEffect, useLayoutEffect, useState } from "react";
import { Tabs, Tab, Modal, Accordion, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { thyrocareDetail } from "../../actions/get-calls";
import lalpath from "../../assets/dust/lalpathlabs.webp";
import Breadcrumb from "../../component/breadcrumb";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
const PackageTestDetail = () => {
    const [packageDetail,setPackageDetail] = useState({})
    const [pincode,setPincode] = useState([])
    const {dId} = useParams();
    const geoCoordinates = useSelector((state) => state.location.value);
    console.log('geoCoordinates: ', geoCoordinates);
    
    useEffect(async () => {
        if (geoCoordinates) {
            setPincode(geoCoordinates.pin_code);
            
        }
    }, [geoCoordinates]);
    
    useEffect(async()=>{
        if(pincode){
            const _thyroProduct = await thyrocareDetail(dId, pincode);

            if (_thyroProduct && _thyroProduct.data && _thyroProduct.data.details) {
              let modifiedChild = _thyroProduct.data.details[0].childs.reduce(function (
                r,
                a
              ) {
                r[a.group_name] = r[a.group_name] || [];
                r[a.group_name].push(a);
                return r;
              },
              Object.create(null));
        
              let sortedChild = [];
              for (var x in modifiedChild) {
                sortedChild.push(modifiedChild[x]);
              }
        
              let mChild = { mData: sortedChild };
              _thyroProduct.data.details[0] = {
                ..._thyroProduct.data.details[0],
                ...mChild,
              };
            }
            setPackageDetail(_thyroProduct.data);
        }

        
    },[pincode,dId])
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    });
    const breadCrumbData = [
        { type: 1, url: "/", name: "Home" },
        { type: 2, name: 'Book Package & Test' },
      ];
  return (
    <>
      <Container className="mhc-package-modal">
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center  mb-4">
          <div>
            <h6 className="m-0">{packageDetail.name}</h6>
            <p className="m-0">Profile Includes (61 Parameters)</p>
          </div>

          <div className="d-flex mt-3 mt-md-0 flex-column flex-md-row align-items-start align-items-md-center">
            {/* <div className="me-0 me-md-3 mb-2 mb-md-0">
              <span>
                <del>INR 999</del>
              </span>{" "}
              <span class="text-primary ms-3">INR 699</span>
            </div> */}
            <img src={lalpath} className="test-icon" />
          </div>
        </div>
        <Accordion defaultActiveKey="" className="">
        {packageDetail && packageDetail.details && packageDetail.details[0] ? (
            <Accordion defaultActiveKey={0}>
              {packageDetail.details[0].mData.map((xItem, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      {xItem[0].group_name} (includes {xItem.length} tests)
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="m-0">
                        {xItem &&
                          xItem.map((mItem, mIndex) => {
                            return <li key={mIndex}>{mItem.name}</li>;
                          })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <div className="mhc-Loding text-center my-5">
              <img src="../loader.gif" alt="loader" />
            </div>
          )}
          
        </Accordion>
        <div className="w-100 text-center my-4">
          <Link to="/package-login" className="btn btn-primary rounded-1">
            Book Package
          </Link>
        </div>
      </Container>
    </>
  );
};

export default PackageTestDetail